<template>
  <div class="copyright">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.copyright {
  padding-bottom: 5px;
}
</style>
